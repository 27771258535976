import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, updatePassword } from '@aws-amplify/auth';
import { getUrl, uploadData } from '@aws-amplify/storage';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ReauthModal from '../components/ReauthModal';
import '../styles/MyAccount.css';

// Configure Amplify with your aws-exports configuration
import awsconfig from '../context/aws-exports';
Amplify.configure(awsconfig);

const MyAccount: React.FC = () => {
    const { isAuthenticated, checkAuthStatus } = useAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                await checkAuthStatus();
                const user = await getCurrentUser();
                // console.log('Fetched user data:', user);
                setUsername(user.username);
                const avatarUrlResult = await getUrl({ key: `avatars/${user.username}` });
                setAvatar(avatarUrlResult as unknown as string); // Cast to string
            } catch (error) {
                // console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [checkAuthStatus]);

    const [username, setUsername] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [avatar, setAvatar] = useState<string>('');
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [alertStatus, setAlertStatus] = useState<{ isSuccess: boolean; message: string } | null>(null);
    const [isReauthModalOpen, setIsReauthModalOpen] = useState<boolean>(false);

    const handlePasswordChange = async () => {
        setIsReauthModalOpen(true);
    };

    const onReauthSuccess = async () => {
        setIsReauthModalOpen(false);
        try {
            console.log('Reauthentication successful');

            // Wait for a brief moment to ensure the authentication state is updated
            await new Promise(resolve => setTimeout(resolve, 1000));

            const user = await getCurrentUser();
            console.log('Authenticated user:', user);
            // console.log('Old password:', currentPassword);
            // console.log('New password:', newPassword);

            const input = {
                oldPassword: currentPassword,
                newPassword,
            };
            await updatePassword(input);
            setAlertStatus({ isSuccess: true, message: 'Password changed successfully' });
        } catch (error: any) {
            console.error('Error changing password:', error);
            setAlertStatus({ isSuccess: false, message: `Failed to change password: ${error.message}` });
        }
    };

    const handleRequestApiKey = async () => {
        try {
            const response = await fetch('/dev/api-key', { method: 'POST' });
            const result = await response.json();
            setAlertStatus({ isSuccess: true, message: `API Key: ${result.apiKey}` });
        } catch (error: any) {
            console.error('Error requesting API key:', error);
            setAlertStatus({ isSuccess: false, message: `Failed to request API key: ${error.message}` });
        }
    };

    const handleAvatarChange = async () => {
        if (!avatarFile) {
            setAlertStatus({ isSuccess: false, message: 'Please select an avatar file to upload' });
            return;
        }

        try {
            await uploadData({
                path: `avatars/${username}`, // Use path instead of key
                data: avatarFile, // Use data instead of body or file
                options: {
                    contentType: avatarFile.type,
                },
            });
            const avatarUrlResult = await getUrl({ key: `avatars/${username}` });
            setAvatar(avatarUrlResult as unknown as string); // Cast to string
            setAlertStatus({ isSuccess: true, message: 'Avatar updated successfully' });
        } catch (error: any) {
            console.error('Error updating avatar:', error);
            setAlertStatus({ isSuccess: false, message: `Failed to update avatar: ${error.message}` });
        }
    };

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="my-account-container">
            <h1>My Account Profile</h1>

            <div className="account-section">
                <h2>## NOTE: This page is currently under development ##</h2>
                <h3>## If you need to change anything reach out via Discord ##</h3>
            </div>

            <div className="account-section">
                <h2>Hello: {username}</h2>
            </div>

            <div className="account-section">
                <h2>Change Password</h2>
                <input
                    type="password"
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <button onClick={handlePasswordChange}>Change Password</button>
            </div>

            <div className="account-section">
                <h2>Request API Key</h2>
                <button onClick={handleRequestApiKey}>Request API Key</button>
            </div>

            <div className="account-section">
                <h2>Update Avatar</h2>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setAvatarFile(e.target.files ? e.target.files[0] : null)}
                />
                <button onClick={handleAvatarChange}>Update Avatar</button>
                {avatar && <img src={avatar} alt="User Avatar" className="avatar-image" />}
            </div>

            {alertStatus && (
                <div className={`alert ${alertStatus.isSuccess ? 'success' : 'error'}`}>
                    {alertStatus.message}
                </div>
            )}

            {isReauthModalOpen && (
                <ReauthModal
                    onClose={() => setIsReauthModalOpen(false)}
                    onReauthSuccess={onReauthSuccess}
                />
            )}
        </div>
    );
};

export default MyAccount;