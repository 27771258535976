import React from 'react';

interface TimeframeInputsProps {
    timeframe: string;
    customTimeframe: boolean;
    fromDate: Date | null;
    toDate: Date | null;
    handleTimeframeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleFromDateChange: (date: Date | null) => void;
    handleToDateChange: (date: Date | null) => void;
    formatDate: (date: Date) => string;
}

const TimeframeInputs: React.FC<TimeframeInputsProps> = ({
    timeframe,
    customTimeframe,
    fromDate,
    toDate,
    handleTimeframeChange,
    handleFromDateChange,
    handleToDateChange,
    formatDate,
}) => {
    return (
        <div className="timeframe-inputs">
            <label htmlFor="Timeframe">Timeframe:</label>
            <select value={timeframe} onChange={handleTimeframeChange} className="search-bar-select">
                <option value="1 day">1 day</option>
                <option value="7 days">7 days</option>
                <option value="14 days">14 days</option>
                <option value="30 days">30 days</option>
                <option value="Custom">Custom</option>
            </select>
            {customTimeframe && (
                <div className="calendar-inputs">
                    <div>
                        <label htmlFor="fromDate">From:</label>
                        <input
                            type="date"
                            id="fromDate"
                            value={fromDate ? formatDate(fromDate) : ''}
                            onChange={(e) => handleFromDateChange(new Date(e.target.value))}
                        />
                    </div>
                    <div>
                        <label htmlFor="toDate">To:</label>
                        <input
                            type="date"
                            id="toDate"
                            value={toDate ? formatDate(toDate) : ''}
                            onChange={(e) => handleToDateChange(new Date(e.target.value))}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimeframeInputs;