import React from 'react';
import ProductCard from './Card';
import { Product } from '../interfaces/Products';

interface ProductListProps {
    products: Product[];
    selectedIds: string[];
    handleSelectionToggle: (productId: string) => void;
    selectAll: boolean;
}

const ProductList: React.FC<ProductListProps> = ({
    products,
    selectedIds,
    handleSelectionToggle,
    selectAll
}) => (
    <div className="card-container">
        {products.map((product: Product) => (
            <ProductCard
                key={product.id}
                product={product}
                isSelected={selectedIds.includes(product.id!)}
                onSelectionToggle={handleSelectionToggle}
                selectAll={selectAll}
            />
        ))}
    </div>
);

export default ProductList;