import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './styles/index.css';
import { AuthProvider } from './context/AuthContext';
import { Amplify } from 'aws-amplify';
import awsconfig from './context/aws-exports';

// Configure Amplify with awsconfig
Amplify.configure(awsconfig);

// Initialize Apollo Client
const client = new ApolloClient({
  uri: 'https://yy2e5kletvcr7fideurejran6y.appsync-api.us-west-2.amazonaws.com/graphql',
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': 'da2-k7s4xbxwfzgjfmwvgxlqw5june',
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);