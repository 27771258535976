// src/hooks/useDateHandlers.ts
import { useState } from 'react';

export const useDateHandlers = () => {
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const handleTimeframeChange = (event: React.ChangeEvent<HTMLSelectElement>, setTimeframe: React.Dispatch<React.SetStateAction<string>>, setCustomTimeframe: React.Dispatch<React.SetStateAction<boolean>>) => {
        const selectedTimeframe = event.target.value;
        setTimeframe(selectedTimeframe);
        if (selectedTimeframe === 'Custom') {
            setCustomTimeframe(true);
        } else {
            setCustomTimeframe(false);
            setFromDate(null);
            setToDate(null);
        }
    };

    const handleFromDateChange = (date: Date | null) => {
        setFromDate(date);
    };

    const handleToDateChange = (date: Date | null) => {
        setToDate(date);
    };

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        fromDate,
        toDate,
        handleTimeframeChange,
        handleFromDateChange,
        handleToDateChange,
        formatDate
    };
};