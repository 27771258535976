import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { signOut } from '@aws-amplify/auth';
import { routes } from '../Routes';
import Logo from '../images/Truss_Logo.png';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
    const { isAuthenticated, clearAuthState } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            clearAuthState();
            navigate('/signout');
        } catch (error) {
            console.error('Sign out error:', error);
        }
        handleMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {isAuthenticated ? (
                <>
                    <Link
                        component={NavLink}
                        to="/my-account"
                        color="inherit"
                        underline="none"
                        variant="button"
                        style={{ color: 'black' }}
                    >
                        <MenuItem onClick={handleMenuClose}>
                            <Typography textAlign="center" style={{ color: 'black' }}>My Account</Typography>
                        </MenuItem>
                    </Link>
                    <MenuItem onClick={handleSignOut} style={{ color: 'black' }}>Signout</MenuItem>
                </>
            ) : (
                <MenuItem onClick={handleMenuClose}>
                    <NavLink to="/login" style={{ color: 'black' }}>Signin</NavLink>
                </MenuItem>
            )}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p style={{ color: 'black' }}>Notifications</p>
            </MenuItem>
            {isAuthenticated && (
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p style={{ color: 'black' }}>Profile</p>
                </MenuItem>
            )}
        </Menu>
    );

    return (
        <Box className="navbar">
            <AppBar position="static" className="navbar">
                <Toolbar>
                    <img src={Logo} alt="TRUSS Logo" />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        className="navbar"
                    >
                        TRUSS
                    </Typography>
                    {isAuthenticated && (
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {routes.filter((route) => route.item === "menu").map((page) => (
                                <Link
                                    key={page.key}
                                    component={NavLink}
                                    to={page.path}
                                    color="inherit"
                                    underline="none"
                                    variant="button"
                                    className="navbar"
                                >
                                    <MenuItem onClick={handleMenuClose}>
                                        <Typography textAlign="center" className="navbar">{page.title}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        {isAuthenticated && (
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                        )}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
};

export default Navbar;