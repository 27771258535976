import React, { useState, FormEvent } from 'react';
import { useAuth } from '../context/AuthContext';
import '../styles/ReauthModal.css';

interface ReauthModalProps {
    onClose: () => void;
    onReauthSuccess: () => void;
}

const ReauthModal: React.FC<ReauthModalProps> = ({ onClose, onReauthSuccess }) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { login } = useAuth();

    const handleReauth = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await login(username, password);
            onReauthSuccess();
        } catch (err) {
            setError('Reauthentication failed. Please try again.');
        }
    };

    return (
        <div className="reauth-modal" aria-modal="true" role="dialog">
            <div className="reauth-modal-content">
                <button className="close" onClick={onClose} aria-label="Close">&times;</button>
                <h2>Reauthenticate</h2>
                <form onSubmit={handleReauth}>
                    <div>
                        <label htmlFor="username">Username:</label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error">{error}</p>}
                    <button type="submit">Reauthenticate</button>
                </form>
            </div>
        </div>
    );
};

export default ReauthModal;