import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFetchData } from '../hooks/useFetchData';
import '../styles/Home.css';

const Home: React.FC = () => {
    const { isAuthenticated, authLoading } = useAuth();
    const navigate = useNavigate();

    const query = `
        query MyQuery {
            totalCountAndPages {
                totalCount
                totalIndicators
                topAuthors
                topCategories
                topTags
            }
        }
    `;

    const { data, loading, error } = useFetchData(
        'https://yy2e5kletvcr7fideurejran6y.appsync-api.us-west-2.amazonaws.com/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'da2-k7s4xbxwfzgjfmwvgxlqw5june',
        },
        body: JSON.stringify({ query }),
    });

    if (authLoading) {
        return <div>Loading...</div>; // Or some loading spinner
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (error) {
        console.error('Error in Home component:', error);
        return <div>Error loading data</div>;
    }

    const totalCountAndPages = data?.totalCountAndPages;

    const handleLinkClick = (field: string, value: string) => {
        localStorage.setItem('searchCriteria', JSON.stringify({ field, value }));
        navigate('/dashboard');
    };

    return (
        <div className="home-container">
            <h1 style={{ textAlign: 'center' }}>Truss Product Trends</h1>
            <div className="home-cards-container">
                <div className="home-card">
                    <h3>Top Five Categories</h3>
                    <div className="home-card-content">
                        {loading ? (
                            <div className="spinner" aria-label="Loading"></div>
                        ) : (
                            <ul className="category-list">
                                {totalCountAndPages?.topCategories?.slice(0, 5).map((category: string, index: number) => (
                                    <li key={index}>
                                        <Link to="/dashboard" onClick={() => handleLinkClick('category', category)}>{category}</Link>
                                    </li>
                                )) || <p>No categories available.</p>}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="home-card">
                    <h3>Total Products and Indicators</h3>
                    <div className="home-card-content">
                        {loading ? (
                            <div className="spinner" aria-label="Loading"></div>
                        ) : (
                            <div className="center-card-number">
                                <div className="number-group">
                                    <b>Products</b>
                                    <p>{totalCountAndPages?.totalCount ?? 'N/A'}</p>
                                </div>
                                <div className="number-group">
                                    <b>Indicators</b>
                                    <p>{totalCountAndPages?.totalIndicators ?? 'N/A'}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="home-card">
                    <h3>Top Authors and Tags</h3>
                    <div className="home-card-content">
                        {loading ? (
                            <div className="spinner" aria-label="Loading"></div>
                        ) : (
                            <>
                                <p><b>Authors: </b>
                                    {totalCountAndPages?.topAuthors?.length ? (
                                        totalCountAndPages.topAuthors.map((author: string, index: number) => (
                                            <React.Fragment key={index}>
                                                <Link to="/dashboard" onClick={() => handleLinkClick('author', author)}>{author}</Link>
                                                {index < totalCountAndPages.topAuthors.length - 1 && ', '}
                                            </React.Fragment>
                                        ))
                                    ) : <span>N/A</span>}
                                </p>
                                <p><b>Tags: </b>
                                    {totalCountAndPages?.topTags?.length ? (
                                        totalCountAndPages.topTags.map((tag: string, index: number) => (
                                            <React.Fragment key={index}>
                                                <Link to="/dashboard" onClick={() => handleLinkClick('tags', tag)}>{tag}</Link>
                                                {index < totalCountAndPages.topTags.length - 1 && ', '}
                                            </React.Fragment>
                                        ))
                                    ) : <span>N/A</span>}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;