import { Amplify } from 'aws-amplify';
import { signIn, signOut, getCurrentUser, updatePassword, AuthUser } from '@aws-amplify/auth';
import awsconfig from './aws-exports';

// Configure Amplify with awsconfig
Amplify.configure(awsconfig);

const AuthService = {
    isAuthenticated: (): boolean => {
        const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
        // console.log('isAuthenticated check:', isAuthenticated);
        return isAuthenticated;
    },

    login: async (username: string, password: string): Promise<AuthUser | { nextStep: string }> => {
        try {
            // console.log('login called with:', { username, password });
            const user = await signIn({ username, password });
            console.log('Signed in user:', user);

            // Handle the next step if the user needs to reset their password
            if ((user as any).nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                console.log(`This is the first login for this user "${username}", please change your password.`);
                return { nextStep: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED' };
            } else {
                const currentUser = await getCurrentUser();
                // console.log('Current authenticated user:', currentUser);
                localStorage.setItem('isAuthenticated', 'true');
                return currentUser;
            }

        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    },

    confirmSignInWithNewPassword: async (username: string, oldPassword: string, newPassword: string): Promise<void> => {
        try {
            // console.log('confirmSignInWithNewPassword called with:', { username, oldPassword, newPassword });
            const user = await signIn({ username, password: oldPassword });
            // console.log('User after signIn:', user);

            if ((user as any).nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                console.log('User needs to confirm new password');
                await updatePassword({
                    oldPassword,
                    newPassword,
                });
                console.log('Password updated successfully');
            } else {
                console.error('Unexpected nextStep:', (user as any).nextStep.signInStep);
            }
        } catch (error) {
            console.error('Password update failed:', error);
            throw error;
        }
    },

    logout: async (): Promise<void> => {
        try {
            await signOut();
            localStorage.removeItem('isAuthenticated');
            console.log('Logout successful');
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    },

    checkAuthStatus: async (): Promise<boolean> => {
        try {
            const user = await getCurrentUser();
            // console.log('User is authenticated:', user);
            return !!user;
        } catch (error) {
            console.error('Error checking authentication status:', error);
            localStorage.removeItem('isAuthenticated');
            return false;
        }
    },
};

export default AuthService;