import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import InsightsPanel from '../components/InsightsPanel';
import SearchBar from '../components/SearchBar';
import ProductList from '../components/ProductList';
import TimeframeInputs from '../components/TimeframeInputs';
import { useConstructFilter } from '../hooks/useConstructFilter';
import { useDateHandlers } from '../hooks/useDateHandlers';
import '../styles/Dashboard.css';
import { Product } from '../interfaces/Products';

const Dashboard: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }

    const storedCriteria = localStorage.getItem('searchCriteria');
    if (storedCriteria) {
      const { field, value } = JSON.parse(storedCriteria);
      addSearchFieldAndValue(field, value);
      localStorage.removeItem('searchCriteria');
    }
  }, [isAuthenticated, navigate]);

  const [searchFields, setSearchFields] = useState<string[]>(['']);
  const [inputValues, setInputValues] = useState<string[]>(['']);
  const [timeframe, setTimeframe] = useState<string>('1 day');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [customTimeframe, setCustomTimeframe] = useState<boolean>(false);
  const { constructFilter } = useConstructFilter(searchFields, inputValues, customTimeframe, fromDate, toDate, timeframe);
  const { formatDate } = useDateHandlers();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showCurlCommand, setShowCurlCommand] = useState<boolean>(false);
  const [searchInitiated, setSearchInitiated] = useState<boolean>(false);

  const handleSelectChange = (index: number, event: ChangeEvent<HTMLSelectElement>) => {
    const newSearchFields = [...searchFields];
    newSearchFields[index] = event.target.value;

    const uniqueFields = new Set(newSearchFields.filter(field => field.trim() !== ''));
    if (uniqueFields.size > 5) {
      alert('You can only have up to 5 unique filters.');
      return;
    }

    setSearchFields(newSearchFields);
  };

  const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };

  const handleAddField = () => {
    const uniqueFields = new Set(searchFields.filter(field => field.trim() !== ''));
    if (uniqueFields.size >= 5) {
      alert('You can only add up to 5 unique filters.');
      return;
    }
    if (searchFields.length < 10) {
      setSearchFields([...searchFields, '']);
      setInputValues([...inputValues, '']);
    }
  };

  const handleRemoveField = (index: number) => {
    const newSearchFields = [...searchFields];
    newSearchFields.splice(index, 1);
    setSearchFields(newSearchFields);

    const newInputValues = [...inputValues];
    newInputValues.splice(index, 1);
    setInputValues(newInputValues);
  };

  const handleSearch = () => {
    // Clear previous search results
    setData(null);
    fetchData();
  };

  const handleSelectAll = () => {
    if (data && data.items) {
      setSelectAll(!selectAll);
      setSelectedIds(
        !selectAll
          ? data.items.map((product: Product) => product.id || '')
          : []
      );
    }
  };

  const handleSelectionToggle = (productId: string) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(productId)) {
        return prevSelectedIds.filter((id) => id !== productId);
      } else {
        return [...prevSelectedIds, productId];
      }
    });
  };

  const handleExportClick = () => {
    const selectedProducts = data?.items
      .filter((product: Product) => selectedIds.includes(product.id || ''))
      .map((product: Product) => {
        let parsedIndicators = {};
        if (typeof product.indicators === 'string') {
          try {
            const cleanString = (product.indicators as string).replace(/\\"/g, '"');
            parsedIndicators = JSON.parse(cleanString);
          } catch (error) {
            console.error('Error parsing indicators:', error);
          }
        } else {
          parsedIndicators = product.indicators || {}; // Provide a default value if indicators is undefined
        }

        const indicatorsArray = Object.entries(parsedIndicators);

        return { ...product, indicators: indicatorsArray };
      });

    const jsonContent = JSON.stringify(selectedProducts, null, 2);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'selected_products.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleTypeClick = (type: string) => {
    addSearchFieldAndValue('type', type);
  };

  const handleCategoryClick = (category: string) => {
    addSearchFieldAndValue('category', category);
  };

  const handleSourceClick = (source: string) => {
    addSearchFieldAndValue('source', source);
  };

  const handleAuthorClick = (author: string) => {
    addSearchFieldAndValue('author', author);
  };

  const handleTagClick = (tag: string) => {
    addSearchFieldAndValue('tags', tag);
  };

  const handleIndustryClick = (industry: string) => {
    addSearchFieldAndValue('industry', industry);
  };

  const handleRegionClick = (region: string) => {
    addSearchFieldAndValue('region', region);
  };

  const handleIndicatorClick = (indicatorType: string, indicatorValue: string) => {
    addSearchFieldAndValue('indicators', `${indicatorType}:${indicatorValue}`);
  };

  const addSearchFieldAndValue = (field: string, value: string) => {
    const uniqueFields = new Set(searchFields.filter(f => f.trim() !== ''));
    if (uniqueFields.size >= 5 && !uniqueFields.has(field)) {
      alert('You can only add up to 5 unique filters.');
      return;
    }
    setSearchFields((prevFields) => [...prevFields, field]);
    setInputValues((prevValues) => [...prevValues, value]);
  };

  // Handler to toggle cURL command display
  const toggleCurlCommand = () => {
    setShowCurlCommand(!showCurlCommand);
  };

  // Function to generate cURL command
  const generateCurlCommand = () => {
    const apiUrl = 'https://api.truss-security.com/product/search';
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': '<YOUR-API-KEY>'
    };

    const command = `curl -X POST ${apiUrl} \\
        -H "Content-Type: ${headers['Content-Type']}" \\
        -H "x-api-key: ${headers['x-api-key']}" \\
        -d '${JSON.stringify(constructFilter())}'`;

    return command;
  };

  const fetchData = async (lastKey: any = null) => {
    setSearchInitiated(true);
    setLoading(true);
    const filterQuery = constructFilter();

    if (lastKey) {
      filterQuery.LastEvaluatedKey = lastKey; // Pass LastEvaluatedKey to the backend
    }

    console.log('Fetching data with filter:', filterQuery);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'sjFjPAYvo63BGHexnng3AaCzW9CCsiHH1GixuaJ3' // Replace with the Prod API key
      },
      body: JSON.stringify(filterQuery)
    };

    const urlPath = 'https://api.truss-security.com/product/search'; // Ensure this is the full URL before making the new container
    // const urlPath = '/product/search'; // Only use this one for development and testing

    try {
      const response = await fetch(urlPath, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
      }

      const result = await response.json();
      const { Items, Count, LastEvaluatedKey } = result.result;

      const products: Product[] = Items;
      setData({
        items: products,
        count: Count,
        lastEvaluatedKey: LastEvaluatedKey,
        topCounts: getTopCounts(products)
      });

    } catch (error) {
      if (error instanceof TypeError) {
        console.error('Network error or CORS issue:', error);
      } else {
        console.error('Error fetching data:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getTopCounts = (products: Product[]) => {
    const countOccurrences = (arr: string[] | undefined) => {
      if (!arr) return {};
      return arr.reduce((acc: Record<string, number>, item: string) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});
    };

    const mergeCounts = (acc: Record<string, number>, counts: Record<string, number>) => {
      for (const [key, value] of Object.entries(counts)) {
        acc[key] = (acc[key] || 0) + value;
      }
      return acc;
    };

    const counts = {
      types: countOccurrences(products.map(p => p.type)),
      categories: countOccurrences(products.map(p => p.category)),
      sources: countOccurrences(products.map(p => p.source)),
      authors: products.reduce((acc, p) => mergeCounts(acc, countOccurrences(p.author)), {}),
      tags: products.reduce((acc, p) => mergeCounts(acc, countOccurrences(p.tags)), {}),
      industries: products.reduce((acc, p) => mergeCounts(acc, countOccurrences(p.industry)), {}),
      regions: products.reduce((acc, p) => mergeCounts(acc, countOccurrences(p.region)), {}),
      indicators: products.reduce((acc, p) => mergeCounts(acc, countOccurrences(Object.keys(p.indicators || {}))), {}),
    };

    const sortCounts = (counts: Record<string, number>) => Object.entries(counts).sort((a, b) => b[1] - a[1]);

    return {
      topTypes: sortCounts(counts.types),
      topCategories: sortCounts(counts.categories),
      topSources: sortCounts(counts.sources),
      topAuthors: sortCounts(counts.authors),
      topTags: sortCounts(counts.tags),
      topIndustries: sortCounts(counts.industries),
      topRegions: sortCounts(counts.regions),
      topIndicators: sortCounts(counts.indicators),
    };
  };

  return (
    <div className="dashboard-container">
      <div className="left-pane">
        <InsightsPanel
          topTypes={data?.topCounts?.topTypes || []}
          topCategories={data?.topCounts?.topCategories || []}
          topSources={data?.topCounts?.topSources || []}
          topAuthors={data?.topCounts?.topAuthors || []}
          topTags={data?.topCounts?.topTags || []}
          topIndustries={data?.topCounts?.topIndustries || []}
          topRegions={data?.topCounts?.topRegions || []}
          topIndicators={data?.topCounts?.topIndicators || []}
          products={data?.items || []}
          handleTypeClick={handleTypeClick}
          handleCategoryClick={handleCategoryClick}
          handleSourceClick={handleSourceClick}
          handleAuthorClick={handleAuthorClick}
          handleTagClick={handleTagClick}
          handleIndustryClick={handleIndustryClick}
          handleRegionClick={handleRegionClick}
          handleIndicatorClick={handleIndicatorClick}
        />
      </div>
      <div className="main-content">
        <div className="control-panel">
          <SearchBar
            searchFields={searchFields}
            inputValues={inputValues}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            handleAddField={handleAddField}
            handleRemoveField={handleRemoveField}
            handleSearch={handleSearch}
            setSearchFields={setSearchFields}
            setInputValues={setInputValues}
          />
        </div>
        <div className="timeframe-limit-container">
          <TimeframeInputs
            timeframe={timeframe}
            customTimeframe={customTimeframe}
            fromDate={fromDate}
            toDate={toDate}
            handleTimeframeChange={(event) => {
              const selectedTimeframe = event.target.value;
              setTimeframe(selectedTimeframe);
              if (selectedTimeframe === 'Custom') {
                setCustomTimeframe(true);
              } else {
                setCustomTimeframe(false);
                setFromDate(null);
                setToDate(null);
              }
            }}
            handleFromDateChange={setFromDate}
            handleToDateChange={setToDate}
            formatDate={formatDate}
          />
        </div>
        <div>
          <button className="search-button" onClick={handleSelectAll}>
            {selectAll ? 'Deselect All' : 'Select All'}
          </button>
          <button className="search-button" onClick={handleExportClick}>Export Selected</button>
          <button className="curl-button search-button" onClick={toggleCurlCommand}>
            {showCurlCommand ? 'Hide cURL Command' : 'Show cURL Command'}
          </button>
          {showCurlCommand && (
            <pre className="curl-command">
              {generateCurlCommand()}
            </pre>
          )}
        </div>
        <div className="total-items-box">
          {searchInitiated && loading ? (
            <div className="spinner" aria-label="Loading"></div>
          ) : (
            <p>Total Items Returned: <b>{data?.items?.length || 0}</b></p>
          )}
        </div>
        <ProductList
          products={data?.items || []}
          selectedIds={selectedIds}
          handleSelectionToggle={handleSelectionToggle}
          selectAll={selectAll}
        />
      </div>
    </div>
  );

}

export default Dashboard;