import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';

const Callback: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                await getCurrentUser();
                navigate('/home');
            } catch (error) {
                console.error('Authentication error:', error);
                navigate('/login');
            }
        };

        handleAuth();
    }, [navigate]);

    return <div>Loading...</div>;
};

export default Callback;