import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
    const { login, confirmSignInWithNewPassword, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/home');
        }
    }, [isAuthenticated, navigate]);

    const isAuthError = (error: any): error is { code: string } => {
        return error && typeof error.code === 'string';
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await login(username, password);
            if ('nextStep' in response && response.nextStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                setError('First time user login, must reset password.');
                setIsNewPasswordRequired(true);
            } else {
                navigate('/home');
            }
        } catch (err: any) {
            if (isAuthError(err)) {
                if (err.code === 'UserNotFoundException') {
                    setError('User does not exist.');
                } else if (err.code === 'NotAuthorizedException') {
                    setError('Incorrect username or password.');
                } else {
                    setError('An error occurred. Please try again.');
                }
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    const handleNewPasswordSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError('Passwords do not match.');
            return;
        }
        try {
            await confirmSignInWithNewPassword(username, password, newPassword);
            setIsNewPasswordRequired(false);
            navigate('/login');
        } catch (err: any) {
            if (isAuthError(err)) {
                if (err.code === 'ExpiredCodeException') {
                    setError('Invalid code provided, please log in again to receive a new code.');
                } else if (err.code === 'LimitExceededException') {
                    setError('Attempt limit exceeded, please try after some time.');
                } else {
                    setError('Failed to update password. Please try again.');
                }
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    const handleRequestNewCode = async () => {
        setError('Please log in again to receive a new code.');
    };

    return (
        <div className="login-container">
            <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/Truss_Logo.png`} alt="Truss Logo" />
            </div>
            {isNewPasswordRequired ? (
                <div className="modal">
                    <form onSubmit={handleNewPasswordSubmit} className="modal-content">
                        <div className="form-group">
                            <label>New Password</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Confirm New Password</label>
                            <input
                                type="password"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p className="error">{error}</p>}
                        <button type="submit">Update Password</button>
                        <button type="button" onClick={handleRequestNewCode}>Request New Code</button>
                    </form>
                </div>
            ) : (
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error">{error}</p>}
                    <button type="submit">Login</button>
                </form>
            )}
        </div>
    );
};

export default Login;