import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { useAuth } from '../context/AuthContext';
import '../styles/Login.css';

const Signout: React.FC = () => {
    const navigate = useNavigate();
    const { clearAuthState } = useAuth();

    const handleSignOut = async () => {
        try {
            await signOut();
            clearAuthState();
            navigate('/login');
        } catch (error) {
            console.error('Sign out error:', error);
            clearAuthState();
            navigate('/login');
        }
    };

    return (
        <div className="login-container">
            <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/Truss_Logo.png`} alt="Truss Logo" />
            </div>
            <div className="modal">
                <div className="modal-content">
                    <button onClick={handleSignOut}>Return to Login Page</button>
                </div>
            </div>
        </div>
    );
};

export default Signout;