import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import AuthService from './AuthService';
import { AuthUser } from '@aws-amplify/auth';

interface AuthContextProps {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<AuthUser | { nextStep: string }>;
    logout: () => Promise<void>;
    checkAuthStatus: () => Promise<void>;
    confirmSignInWithNewPassword: (username: string, oldPassword: string, newPassword: string) => Promise<void>;
    authLoading: boolean;
    clearAuthState: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        const authStatus = localStorage.getItem('isAuthenticated') === 'true';
        return authStatus;
    });
    const [authLoading, setAuthLoading] = useState<boolean>(true);

    const checkAuthStatus = async () => {
        setAuthLoading(true);
        try {
            const isAuthenticated = await AuthService.checkAuthStatus();
            setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                localStorage.setItem('isAuthenticated', 'true');
            } else {
                localStorage.removeItem('isAuthenticated');
            }
        } catch (error) {
            console.error('Error checking authentication status:', error);
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
        } finally {
            setAuthLoading(false);
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const login = async (username: string, password: string): Promise<AuthUser | { nextStep: string }> => {
        try {
            const user = await AuthService.login(username, password);
            if ('nextStep' in user) {
                setIsAuthenticated(false);
                return user;
            }
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true');
            return user;
        } catch (error) {
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
            throw error;
        }
    };

    const confirmSignInWithNewPassword = async (username: string, oldPassword: string, newPassword: string) => {
        await AuthService.confirmSignInWithNewPassword(username, oldPassword, newPassword);
    };

    const clearAuthState = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
    };

    const logout = async () => {
        try {
            await AuthService.logout();
            clearAuthState();
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, checkAuthStatus, confirmSignInWithNewPassword, authLoading, clearAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};