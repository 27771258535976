import React, { ChangeEvent, useEffect } from 'react';

interface SearchBarProps {
    searchFields: string[];
    inputValues: string[];
    handleSelectChange: (index: number, event: ChangeEvent<HTMLSelectElement>) => void;
    handleInputChange: (index: number, event: ChangeEvent<HTMLInputElement>) => void;
    handleAddField: () => void;
    handleRemoveField: (index: number) => void;
    handleSearch: () => void;
    setSearchFields: React.Dispatch<React.SetStateAction<string[]>>;
    setInputValues: React.Dispatch<React.SetStateAction<string[]>>;
}

const SearchBar: React.FC<SearchBarProps> = ({
    searchFields,
    inputValues,
    handleSelectChange,
    handleInputChange,
    handleAddField,
    handleRemoveField,
    handleSearch,
    setSearchFields,
    setInputValues
}) => {
    const isIndicatorsSelected = searchFields.includes('indicators');
    const categoryIndex = searchFields.findIndex(field => field === 'category');

    useEffect(() => {
        if (isIndicatorsSelected && categoryIndex === -1) {
            setSearchFields(prevFields => [...prevFields, 'category']);
            setInputValues(prevValues => [...prevValues, '']);
        }
    }, [isIndicatorsSelected, categoryIndex, setSearchFields, setInputValues]);

    return (
        <div className="search-bar-container">
            {searchFields.map((field, index) => (
                <div key={index} className="search-row">
                    <div className="search-bar-item">
                        <select
                            name="field"
                            value={field}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleSelectChange(index, e)}
                            className="search-bar-select"
                        >
                            <option value="">Select Field</option>
                            <option value="title">Title</option>
                            <option value="author">Author</option>
                            <option value="type">Type</option>
                            <option value="category">Category</option>
                            <option value="source">Source</option>
                            <option value="tags">Tags</option>
                            <option value="industry">Industry</option>
                            <option value="region">Region</option>
                            <option value="indicators">Indicators</option>
                        </select>
                        {field === 'indicators' ? (
                            <>
                                <input
                                    type="text"
                                    placeholder="Indicator Type"
                                    value={inputValues[index].split(':')[0] || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const newValue = `${e.target.value}:${inputValues[index].split(':')[1] || ''}`;
                                        handleInputChange(index, { ...e, target: { ...e.target, value: newValue } });
                                    }}
                                    className="search-bar-input"
                                />
                                <input
                                    type="text"
                                    placeholder="Indicator Value"
                                    value={inputValues[index].split(':')[1] || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const newValue = `${inputValues[index].split(':')[0] || ''}:${e.target.value}`;
                                        handleInputChange(index, { ...e, target: { ...e.target, value: newValue } });
                                    }}
                                    className="search-bar-input"
                                />
                            </>
                        ) : (
                            <input
                                type="text"
                                placeholder={`Search by ${field}...`}
                                value={inputValues[index] || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e)}
                                className="search-bar-input"
                            />
                        )}
                        <button onClick={() => handleRemoveField(index)} className="search-button">X</button>
                    </div>
                </div>
            ))}
            {isIndicatorsSelected && categoryIndex === -1 && (
                <div className="search-row">
                    <div className="search-bar-item">
                        <select
                            name="field"
                            value="category"
                            onChange={() => { }}
                            className="search-bar-select"
                            disabled
                        >
                            <option value="category">Category</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search by category..."
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(searchFields.length - 1, e)}
                            className="search-bar-input"
                        />
                    </div>
                </div>
            )}
            <button onClick={handleAddField} className="search-button">Add Filter</button>
            <button className="search-button" onClick={handleSearch}>Search</button>
        </div>
    );
};

export default SearchBar;