interface AWSConfig {
    aws_project_region: string;
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
}

const awsconfig: AWSConfig = {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id: 'us-west-2:cb19cceb-75a9-425e-9686-8eb827860e64',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_MI6SCTqlc',
    aws_user_pools_web_client_id: '2lbrc9fan64c580i66munst2rk',
};

export default awsconfig;