import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './context/aws-exports';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './pages/Login';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import MyAccount from './pages/MyAccount';
import Callback from './pages/Callback';
import Signout from './pages/Signout';
import { useAuth, AuthProvider } from './context/AuthContext';
import './styles/App.css';

Amplify.configure(awsconfig);

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const App: React.FC = () => (
    <AuthProvider>
        <div className="app-container">
            <Navbar />
            <div className="content-wrapper">
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/callback" element={<Callback />} />
                    <Route path="/signout" element={<Signout />} />
                    <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
                </Routes>
            </div>
            <Footer />
        </div>
    </AuthProvider>
);

export default App;