import React, { FC, ReactElement } from "react";
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { Twitter } from "@mui/icons-material";
import { LinkedIn } from "@mui/icons-material";
import { YouTube } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

const Footer: FC = (): ReactElement => {

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 2,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Sign up for our newsletter
            </Typography>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="outlined-basic" label="Email Address" variant="outlined" />
            </Box>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Be the first to know about our special offers, new product launches, and events.
            </Typography>
            <Link href="https://twitter.com/truss_security" color="inherit">
              <Twitter />
            </Link>
            <Link href="https://linkedin.com/company/truss_security" color="inherit">
              <LinkedIn />
            </Link>
            <Link href="https://www.youtube.com/channel/UCjzO-8_mv3iKKf6baDWaYfw" color="inherit">
              <YouTube />
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Links
            </Typography>
            <Link
              key='dashboard-route'
              component={NavLink}
              to='/home'
              color="inherit"
              underline="none"
              variant="button"
            >
              <Typography variant="body2" color="text.secondary">
                Home
              </Typography>
            </Link>
            <Link
              key='dashboard-route'
              component={NavLink}
              to='/dashboard'
              color="inherit"
              underline="none"
              variant="button"
            >
              <Typography variant="body2" color="text.secondary">
                Dashboard
              </Typography>
            </Link>
            <Link
              key='dashboard-route'
              component={NavLink}
              to='/my-account'
              color="inherit"
              underline="none"
              variant="button"
            >
              <Typography variant="body2" color="text.secondary">
                My Account
              </Typography>
            </Link>
            <Link
              key='agents-route'
              component={NavLink}
              to='/signout'
              color="inherit"
              underline="none"
              variant="button"
            >
              <Typography variant="body2" color="text.secondary">
                Signout
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Help
            </Typography>
            <Link href="https://truss-security.github.io/truss-docs/api" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                API
              </Typography>
            </Link>
            <Link href="https://truss-security.github.io/truss-docs/dashboard" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                Dashboard
              </Typography>
            </Link>
            <Link href="https://truss-security.com/faqs" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                FAQs
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About
            </Typography>
            <Link href="https://www.truss-security.com" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                Home
              </Typography>
            </Link>
            <Link href="https://truss-security.com/contact" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                Contact Us
              </Typography>
            </Link>
            <Link href="https://truss-security.com/blog" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                Blog
              </Typography>
            </Link>
            <Link href="https://truss-security.github.io/truss-docs/" variant="body2" color="text.secondary" underline="none">
              <Typography variant="body2" color="text.secondary">
                Documentation
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://truss-security.com/">
              Truss Security Inc.
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;