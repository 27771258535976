export const useConstructFilter = (
    searchFields: string[],
    inputValues: string[],
    customTimeframe: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    timeframe: string,
) => {
    const constructFilter = () => {
        const filter: any = {};

        if (customTimeframe && fromDate && toDate) {
            filter.startDate = fromDate.getTime();
            filter.endDate = toDate.getTime();
        } else if (timeframe !== 'All') {
            const now = new Date();
            const timeframeMapping: { [key: string]: number } = {
                '1 day': 1,
                '7 days': 7,
                '14 days': 14,
                '30 days': 30
            };

            const daysAgo = timeframeMapping[timeframe];
            if (daysAgo !== undefined) {
                now.setDate(now.getDate() - daysAgo);
                filter.startDate = now.getTime();
            }
        }

        const indicatorsFilter: any = {};

        for (let i = 0; i < searchFields.length; i++) {
            const field = searchFields[i];
            const value = inputValues[i];

            if (value) {
                if (field === 'indicators') {
                    const [indicatorType, indicatorValue] = value.split(':');
                    if (indicatorsFilter[indicatorType]) {
                        indicatorsFilter[indicatorType].push(indicatorValue);
                    } else {
                        indicatorsFilter[indicatorType] = [indicatorValue];
                    }
                } else if (['author', 'tags', 'industry', 'region'].includes(field)) {
                    filter[field] = [value]; // Wrap in array
                } else {
                    filter[field] = value;
                }
            }
        }

        if (Object.keys(indicatorsFilter).length > 0) {
            filter.indicators = indicatorsFilter;
        }

        return filter;
    };

    return { constructFilter };
};