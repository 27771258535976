import React, { useState, useEffect, useRef } from 'react';
import { Product } from '../interfaces/Products';
import '../styles/Card.css';

// Define the props for the component
interface ProductCardProps {
    product: Product;
    isSelected: boolean;
    onSelectionToggle: (productId: string) => void;
    selectAll: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, isSelected, onSelectionToggle, selectAll }) => {
    const [localSelected, setLocalSelected] = useState(isSelected);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLocalSelected(selectAll);
    }, [selectAll]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target as Node)) {
                closeModal();
            }
        };

        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isModalOpen]);

    const toggleSelection = () => {
        const newSelected = !localSelected;
        setLocalSelected(newSelected);
        onSelectionToggle(product.id!);
    };

    const openModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden'; // Disable scrolling on body
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = ''; // Reset the overflow style
    };

    // Define formatTime function
    const formatTime = (timestamp?: number) => {
        if (!timestamp) return 'N/A';
        const date = new Date(timestamp);
        const MM = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month
        const DD = String(date.getUTCDate()).padStart(2, '0'); // Day
        const YYYY = date.getUTCFullYear(); // Year
        const HH = String(date.getUTCHours()).padStart(2, '0'); // Hours
        const MM2 = String(date.getUTCMinutes()).padStart(2, '0'); // Minutes
        const SS = String(date.getUTCSeconds()).padStart(2, '0'); // Seconds

        return `${MM}-${DD}-${YYYY} ${HH}:${MM2}:${SS} (UTC)`;
    };

    // Define renderArrayItems function
    const renderArrayItems = (items?: string | string[]): string => {
        if (Array.isArray(items)) {
            return items.join(', ');
        } else if (typeof items === 'string') {
            return items.replace(/^\[|\]$/g, '');
        } else {
            return 'N/A';
        }
    };

    return (
        <div className="card">
            <h2 className="card-title">{product.title}</h2>
            <div className="card-content">
                <div className="card-details">
                    <p><b>Category:</b> {product.category || 'N/A'}</p>
                    <p><b>Time:</b> {formatTime(product.timestamp)}</p>
                </div>
                <div className="card-details">
                    <p><b>Rating:</b> {product.rating || 'N/A'}</p>
                    <p><b>Downloads:</b> {product.downloads || 'N/A'}</p>
                </div>
                <div className="card-actions">
                    <button onClick={openModal} className="card-button">View Details</button>
                    <div className="select-section">
                        <label className="select-label">
                            Select
                            <input
                                type="checkbox"
                                checked={localSelected}
                                onChange={toggleSelection}
                                className="card-checkbox"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div className="card-details">
                <p><b>Author:</b> {renderArrayItems(product.author) || 'N/A'}</p>
                <p><b>Region:</b> {renderArrayItems(product.region) || 'N/A'}</p>
                <p><b>Industry:</b> {renderArrayItems(product.industry) || 'N/A'}</p>
            </div>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content" ref={modalRef}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <h2 className="header-name">{product.title}</h2>
                        <div className="modal-body">
                            <div className="modal-left">
                                <p><b>Category:</b> {product.category || 'N/A'}</p>
                                <p><b>Type:</b> {product.type || 'N/A'}</p>
                                <p><b>Author:</b> {renderArrayItems(product.author) || 'N/A'}</p>
                                <p><b>Time:</b> {formatTime(product.timestamp)}</p>
                                <p><b>Publication Date:</b> {product.pubDate || 'N/A'}</p>
                                <p><b>Tags:</b> {renderArrayItems(product.tags) || 'N/A'}</p>
                                <p><b>Region:</b> {renderArrayItems(product.region) || 'N/A'}</p>
                                <p><b>Industry:</b> {renderArrayItems(product.industry) || 'N/A'}</p>
                            </div>
                            <div className="modal-right">
                                <p><b>Rating:</b> {product.rating || 'N/A'}</p>
                                <p><b>Downloads:</b> {product.downloads || 'N/A'}</p>
                                <p><b>Validators:</b> {renderArrayItems(product.validators) || 'N/A'}</p>
                                <p><b>ID:</b> {product.id || 'N/A'}</p>
                                {product.reference && Array.isArray(product.reference) ? (
                                    <div>
                                        <b>Reference: </b>
                                        <ul>
                                            {product.reference.map((ref: string, index: number) => (
                                                <li key={index}>
                                                    <a href={ref.trim()} target='_blank' rel='noopener noreferrer'>{ref.trim()}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div>
                                        <b>Reference: </b>
                                        {typeof product.reference === 'string' && (
                                            (() => {
                                                const references = (product.reference as string).split(',');
                                                return (
                                                    <p>
                                                        <b>Reference: </b>
                                                        {references.map((ref: string, index: number) => {
                                                            const trimmedRef = ref.trim().replace(/\[|\]$/g, '');
                                                            return (
                                                                <span key={index}>
                                                                    <a href={trimmedRef} target='_blank' rel='noopener noreferrer'>{trimmedRef}</a>
                                                                    {index !== references.length - 1 && ', '}
                                                                </span>
                                                            );
                                                        })}
                                                    </p>
                                                );
                                            })()
                                        )}
                                    </div>
                                )}
                                {product.chainTxn && product.chainTxn.transaction_id && (
                                    <p>
                                        <b>Blockchain Transaction:</b>
                                        <a href={`https://hashscan.io/testnet/transaction/${product.chainTxn.transaction_id}`} target='_blank' rel='noopener noreferrer'>
                                            {product.chainTxn.transaction_id}
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="modal-checkbox-container">
                            <label className="modal-checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={isSelected}
                                    onChange={toggleSelection}
                                />
                                <span className="custom-checkbox"></span>
                                Select Product
                            </label>
                        </div>
                        <p className="indicators-label"><b>Indicators:</b></p>
                        <div className="modal-indicators">
                            <ul className="indicator-list">
                                {product.indicators && typeof product.indicators === 'object' && (
                                    <li>
                                        <div>
                                            <pre>
                                                <code>{JSON.stringify(product.indicators, null, 2)}</code>
                                            </pre>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductCard;