// File: ./src/Routes.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './context/aws-exports';
import Login from './pages/Login';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import MyAccount from './pages/MyAccount';
import Callback from './pages/Callback';
import Signout from './pages/Signout';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useAuth, AuthProvider } from './context/AuthContext';

Amplify.configure(awsconfig);

const routes = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/home',
        component: Home,
        item: 'menu'
    },
    {
        key: 'dashboard-route',
        title: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        item: 'menu'
    },
    {
        key: 'myaccount-route',
        title: 'My Account',
        path: '/my-account',
        component: MyAccount,
        item: 'menu'
    }
];

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const App: React.FC = () => (
    <AuthProvider>
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<Callback />} />
                <Route path="/signout" element={<Signout />} />
                <Route
                    path="/home"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-account"
                    element={
                        <PrivateRoute>
                            <MyAccount />
                        </PrivateRoute>
                    }
                />
            </Routes>
            <Footer />
        </Router>
    </AuthProvider>
);

export default App;
export { routes };