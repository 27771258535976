import React, { useState } from 'react';
import '../styles/InsightsPanel.css';
import { Product } from '../interfaces/Products'; // Import Product interface

interface InsightsPanelProps {
    topTypes: [string, number][];
    topCategories: [string, number][];
    topSources: [string, number][];
    topAuthors: [string, number][];
    topTags: [string, number][];
    topIndustries: [string, number][];
    topRegions: [string, number][];
    topIndicators: [string, number][];
    handleTypeClick: (type: string) => void;
    handleCategoryClick: (category: string) => void;
    handleSourceClick: (source: string) => void;
    handleAuthorClick: (author: string) => void;
    handleTagClick: (tag: string) => void;
    handleIndustryClick: (industry: string) => void;
    handleRegionClick: (region: string) => void;
    handleIndicatorClick: (indicatorType: string, indicatorValue: string) => void;
    products: Product[]; // Add products prop
}

const InsightsPanel: React.FC<InsightsPanelProps> = ({
    topTypes,
    topCategories,
    topSources,
    topAuthors,
    topTags,
    topIndustries,
    topRegions,
    topIndicators,
    handleTypeClick,
    handleCategoryClick,
    handleSourceClick,
    handleAuthorClick,
    handleTagClick,
    handleIndustryClick,
    handleRegionClick,
    handleIndicatorClick,
    products, // Destructure products prop
}) => {
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [expandedIndicator, setExpandedIndicator] = useState<string | null>(null);

    const toggleExpand = (key: string) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [key]: !prevExpanded[key],
        }));
    };

    const renderList = (items: [string, number][], type: string, handleClick: (item: string) => void) => (
        <ul>
            {items.slice(0, expanded[type] ? items.length : 10).map(([item, count]) => (
                <li key={item} className="insight-count" onClick={() => handleClick(item)}>
                    <span className="insight-item">{item}</span> ({count})
                </li>
            ))}
            {items.length > 10 && (
                <li className="insight-toggle" onClick={() => toggleExpand(type)}>
                    <span className="toggle-text">{expanded[type] ? 'Less' : 'More'}</span>
                </li>
            )}
        </ul>
    );

    const renderIndicatorValues = (indicatorType: string) => {
        if (!expandedIndicator) return null;

        const indicatorCounts: { [key: string]: number } = {};

        products.forEach((product) => {
            const indicators = product.indicators || {};
            const values = indicators[indicatorType];
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    indicatorCounts[value] = (indicatorCounts[value] || 0) + 1;
                });
            }
        });

        const sortedIndicatorValues = Object.entries(indicatorCounts).sort((a, b) => b[1] - a[1]);

        return (
            <ul>
                {sortedIndicatorValues.map(([value, count]) => (
                    <li key={value} className="insight-count" onClick={() => handleIndicatorClick(indicatorType, value)}>
                        <span className="insight-item">{value}</span> ({count})
                    </li>
                ))}
            </ul>
        );
    };

    const toggleIndicatorExpand = (indicatorType: string) => {
        if (expandedIndicator === indicatorType) {
            setExpandedIndicator(null);
        } else {
            setExpandedIndicator(indicatorType);
        }
    };

    return (
        <div className="left-pane">
            <h3>Product Insights</h3>
            <div>
                Types:
                {renderList(topTypes, 'types', handleTypeClick)}
            </div>
            <div>
                Categories:
                {renderList(topCategories, 'categories', handleCategoryClick)}
            </div>
            <div>
                Sources:
                {renderList(topSources, 'sources', handleSourceClick)}
            </div>
            <div>
                Authors:
                {renderList(topAuthors, 'authors', handleAuthorClick)}
            </div>
            <div>
                Tags:
                {renderList(topTags, 'tags', handleTagClick)}
            </div>
            <div>
                Industries:
                {renderList(topIndustries, 'industries', handleIndustryClick)}
            </div>
            <div>
                Regions:
                {renderList(topRegions, 'regions', handleRegionClick)}
            </div>
            <div>
                Indicators:
                <ul>
                    {topIndicators.slice(0, expanded.indicators ? topIndicators.length : 10).map(([indicator, count]) => (
                        <li key={indicator} className="insight-count">
                            <span className="insight-item" onClick={() => toggleIndicatorExpand(indicator)}>{indicator}</span> ({count})
                            {expandedIndicator === indicator && (
                                <div className="indicator-values">
                                    {renderIndicatorValues(indicator)}
                                </div>
                            )}
                        </li>
                    ))}
                    {topIndicators.length > 10 && (
                        <li className="insight-toggle" onClick={() => toggleExpand('indicators')}>
                            <span className="toggle-text">{expanded.indicators ? 'Less' : 'More'}</span>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default InsightsPanel;